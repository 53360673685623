import map from 'lodash/map';
import filter from 'lodash/filter';
import find from 'lodash/find';
import some from 'lodash/some';
import get from 'lodash/get';
import isBoolean from 'lodash/isBoolean';
import forEach from 'lodash/forEach';
import sortBy from 'lodash/sortBy';
import last from 'lodash/last';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import flatMap from 'lodash/flatMap';
import orderBy from 'lodash/orderBy';
import includes from 'lodash/includes';
import React, { createContext, useContext, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';
import {
  IN_APP_CONTENT_TYPE__FAQ,
  IN_APP_CONTENT_TYPE__MEDICAL_INFORMATION,
} from '../common/constants';
import useReconcile from '../common/utilsClient/useReconcile';
import { selectToken } from '../store/token';
import {
  isNotCompleted,
  getStaged,
  STATE_DRAFT,
  deleteDraft,
} from '../store/stage';
import useOnline from '../utils/useOnline';
import useQuestionnaire, {
  useLatestDraftData,
} from '../utils/useQuestionnaire';
import { getRejectedParticipationsClientSide } from '../common/utils/getRejectedParticipations';

const DataContext = createContext();

export const GET_ACTIVITIES = gql`
  query GetActivities {
    state
    my {
      id
      languagePreference
      activities {
        id
        project {
          id
          name
          logoUrl
          languages
        }
        templates {
          id
          type
          subject
          content
          language
        }
        isConsent
        milestoneName
        clinicianName
        clinicianPhotoUrl
        participationId
      }
      answersSheets {
        id
        state
        patientId
        activityId
        orderInActivity
        pipedResults
        questionnaire {
          id
          name
          minutesToComplete
          translations {
            language
            questionnaireName
          }
        }
      }
    }
  }
`;

const DataProvider = ({
  // eslint-disable-next-line react/prop-types
  children,
}) => {
  const { i18n } = useTranslation();
  const online = useOnline();
  const { loading, data } = useQuery(GET_ACTIVITIES, {
    fetchPolicy: online
      ? 'cache-and-network' // try to keep this query up to date when online
      : 'cache-first',
  });
  const isLoggedIn = !!useSelector(selectToken);
  const activities = get(data, 'my.activities');
  const tokenState = get(data, 'state');
  const staged = useSelector(getStaged);
  const myAnswersSheets = get(data, 'my.answersSheets');

  const byActivityId = useMemo(() => {
    return groupBy(sortBy(myAnswersSheets, 'orderInActivity'), 'activityId');
  }, [myAnswersSheets]);

  const activitiesByConsent = useMemo(() => {
    const rejectedParticipations = getRejectedParticipationsClientSide(
      myAnswersSheets,
      activities,
      staged,
    );
    const filteredActivities = filter(
      activities,
      (activity) => !includes(rejectedParticipations, activity.participationId),
    );
    return orderBy(
      filteredActivities,
      ['participationId', 'isConsent'],
      ['asc', 'desc'],
    );
  }, [activities, myAnswersSheets, staged]);

  const currentActivity = useMemo(() => {
    const withNotCompletedAnswersSheet = find(activitiesByConsent, (activity) =>
      some(byActivityId[activity.id], isNotCompleted(staged)),
    );
    if (withNotCompletedAnswersSheet) {
      return withNotCompletedAnswersSheet;
    }
    return last(activities);
  }, [activities, byActivityId, staged, activitiesByConsent]);

  const currentAnswersSheet = useMemo(() => {
    return find(
      currentActivity && byActivityId[currentActivity.id],
      isNotCompleted(staged),
    );
  }, [staged, byActivityId, currentActivity]);

  const currentAnswersSheetId = currentAnswersSheet && currentAnswersSheet.id;

  const answersSheets = useMemo(() => {
    const answerSheetsByStaged = filter(
      flatMap(activitiesByConsent, (activity) =>
        map(byActivityId[activity.id], ({ id, state, questionnaire }) => ({
          id,
          state,
          questionnaire,
          draftState: staged[id] && staged[id].state,
          isConsent: activity.isConsent,
        })),
      ),
      (answersSheet) =>
        !answersSheet.isConsent ||
        (answersSheet.isConsent && answersSheet.state !== 'COMPLETED'),
    );

    return answerSheetsByStaged;
  }, [staged, byActivityId, activitiesByConsent]);

  const projectLogoUrl = get(currentActivity, 'project.logoUrl');
  const projectLanguagePreference = useReconcile(
    get(currentActivity, 'project.languages'),
  );
  const patientLanguagePreference = useReconcile(
    get(data, 'my.languagePreference'),
  );
  const dispatch = useDispatch();
  useEffect(() => {
    forEach(answersSheets, ({ id, state }) => {
      // NOTE: state is remote value take from the server; so if it says "COMPLETED"
      //       it means that the server has confirmed completion
      if (
        state === 'COMPLETED' &&
        staged[id] &&
        staged[id].state === STATE_DRAFT
      ) {
        dispatch(deleteDraft(id));
      }
    });
  }, [dispatch, staged, answersSheets]);
  useLatestDraftData(currentAnswersSheetId);

  const {
    isFirstInActivity,
    isStarted,
    // isCompleted,
    // mostRecentDraft,
    // localDraftNeedsUpdate,
    loadingQuestionnaire,
    translations,
    // currentQuestionId,
  } = useQuestionnaire(currentAnswersSheetId, null, {
    preferRemoteDraftIfNewer: true,
  });

  const faqTemplates = filter(
    currentActivity?.templates,
    (template) =>
      template.type === IN_APP_CONTENT_TYPE__FAQ &&
      template.language === i18n.language,
  );
  const hasFaq = !isEmpty(faqTemplates);
  const medicalInformationTemplates = filter(
    currentActivity?.templates,
    (template) =>
      template.type === IN_APP_CONTENT_TYPE__MEDICAL_INFORMATION &&
      template.language === i18n.language,
  );
  const hasMedicalInformation = !isEmpty(medicalInformationTemplates);

  const value = useMemo(
    () => ({
      data,
      answersSheetId: currentAnswersSheetId,
      currentActivity,
      answersSheets,
      translations,
      projectLanguagePreference,
      patientLanguagePreference,
      projectLogoUrl,
      isLoggedIn,
      loading,
      loadingQuestionnaire,
      tokenState,
      shouldContinueActivity:
        isBoolean(isFirstInActivity) &&
        isBoolean(isStarted) &&
        (!isFirstInActivity || isStarted),
      faqTemplates,
      hasFaq,
      medicalInformationTemplates,
      hasMedicalInformation,
    }),
    [
      data,
      currentAnswersSheetId,
      currentActivity,
      answersSheets,
      translations,
      projectLanguagePreference,
      patientLanguagePreference,
      projectLogoUrl,
      isLoggedIn,
      loading,
      loadingQuestionnaire,
      tokenState,
      isFirstInActivity,
      isStarted,
      faqTemplates,
      hasFaq,
      medicalInformationTemplates,
      hasMedicalInformation,
    ],
  );

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

export default DataProvider;

export const useDataProvider = () => useContext(DataContext);
