import forEach from 'lodash/forEach';
import isPlainObject from 'lodash/isPlainObject';
import { levels, valueToLevel } from './constants';
import forEachKey from './forEachKey';
import BaseLogger from './BaseLogger';
import getMaxLogLevel from './getMaxLogLevel';

/**
 * @typedef {import('./constants').LogLevel} LogLevel
 */

/**
 * @typedef {import('./getMaxLogLevel').LogLevelConfig} LogLevelConfig
 */

/**
 * @extends {BaseLogger}
 */
export class Logger extends BaseLogger {
  /**
   * @param {object} options
   * @param {string} [options.topic]
   * @param {LogLevelConfig} [options.level]
   * @param {LogLevel} [options.maxLevel]
   * @param {import('./BaseLogger').ILogger} [options.parent]
   */
  constructor({
    topic = 'logs',
    level = 'info',
    maxLevel = 'trace',
    parent,
  } = {}) {
    super();
    this.topic = topic;
    /** @type {(typeof levels)[LogLevel]} */
    this.maxValue = levels[maxLevel];
    /** @type {import('./BaseLogger').ILogger | undefined} */
    this.parent = parent;
    /** @type {import('./BaseLogger').ILogger | undefined} */
    this.proxy = undefined;
    /** @type {{ [topic: string]: Logger }} */
    this.loggers = {};
    this.setLevel(level);
    /** @type {LogLevelConfig | undefined} */
    this.level = undefined;
    /** @type {(typeof levels)[LogLevel]} */
    this.value = this.maxValue;
  }

  /**
   * @param {string} topic
   * @param {object} options
   * @param {LogLevel} [options.maxLevel]
   * @returns {Logger}
   */
  create(topic, options = {}) {
    const { maxLevel } = options;
    if (!topic) {
      throw new Error('Creating child logger requires a topic');
    }
    if (this.loggers[topic]) {
      throw new Error(`Logger for topic "${topic}" already exists`);
    }
    const logger = new Logger({
      topic,
      level: this.getLevelFor(topic),
      maxLevel,
      parent: this,
    });
    this.loggers[topic] = logger;
    return logger;
  }

  /**
   * @param {object} options
   * @param {LogLevel} options.level
   * @param {string} options.message
   */
  log(options) {
    const { level, message, ...other } = options;
    this[level](message, other);
  }

  /**
   * @param {string} topic
   * @returns {LogLevelConfig | undefined}
   */
  getLevelFor(topic) {
    if (typeof this.level === 'string') {
      return this.level;
    }
    if (this.level && isPlainObject(this.level)) {
      if (this.level[topic]) {
        return this.level[topic];
      }
      if (this.level['*']) {
        return this.level['*'];
      }
      return valueToLevel[this.value];
    }
    return this.level;
  }

  /**
   * @param {LogLevelConfig | undefined} level
   */
  setLevel(level) {
    if (typeof level === 'string') {
      this.level = level;
      this.value = levels[level];
    } else if (level && isPlainObject(level)) {
      this.level = level;
      this.value = levels[getMaxLogLevel(level)];
    } else {
      throw Error(`Unknown log level ${level}`);
    }
    forEach(this.loggers, (logger, topic) => {
      logger.setLevel(this.getLevelFor(topic));
    });
  }

  /**
   * @param {import('./BaseLogger').ILogger} logger
   */
  setProxy(logger) {
    if (!this.parent) {
      this.proxy = logger;
    } else {
      this.warn(
        'Setting logger proxy does not make sense if parent is already set',
      );
    }
  }
}

const noop = new BaseLogger();

forEachKey(levels, (value, level) => {
  /**
   * @param {string} message
   * @param {import('./BaseLogger').LogDetails} [meta]
   */
  Logger.prototype[level] = function logLevelFn(message, meta) {
    if (value <= this.value && value <= this.maxValue) {
      const newMeta = {
        ...meta,
        topic:
          meta && meta.topic ? `${this.topic}.${meta.topic}` : `${this.topic}`,
      };
      (this.parent || this.proxy || noop)[level](message, newMeta);
    }
  };
});

const logger = new Logger();

export default logger;
